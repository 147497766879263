
const Home = {
    text: 'Home',
    link: '/home',
    icon: 'icon-home'
};

const headingGeneralSig = {
  text: 'Cadastros',
  heading: true
};

const StudentsSig = {
  text: 'Alunos',
  link: '/studentsSig',
  icon: 'icon-note',
  submenu: [
    {
      text: 'Buscar',
      link: '/studentsSig/list'
    },
    {
      text: 'Cadastrar',
      link: '/studentsSig/edit'
    }
  ]
}

const Discipline = {
  text: 'Disciplinas',
  link: '/discipline',
  icon: 'icon-note',
  submenu: [
      {
          text: 'Buscar',
          link: '/discipline/list'
      },
      {
          text: 'Cadastrar',
          link: '/discipline/edit'
      }
  ]
};
const Test = {
  text: 'Testes',
  link: '/test',
  icon: 'icon-note',
  submenu: [
      {
          text: 'Buscar',
          link: '/test/list'
      },
      {
          text: 'Cadastrar',
          link: '/test/edit'
      }
  ]
};
const Coordinator = {
  text: 'Coordenadores',
  link: '/coordinator',
  icon: 'icon-note',
  submenu: [
      {
          text: 'Buscar',
          link: '/coordinator/list'
      },
      {
          text: 'Cadastrar',
          link: '/coordinator/edit'
      }
  ]
};
const Book = {
  text: 'Livros',
  link: '/book',
  icon: 'icon-note',
  submenu: [
      {
          text: 'Buscar',
          link: '/book/list'
      },
      {
          text: 'Cadastrar',
          link: '/book/edit'
      }
  ]
};
const Calendar = {
  text: 'Calendários',
  link: '/calendar',
  icon: 'icon-note',
  submenu: [
      {
          text: 'Buscar',
          link: '/calendar/list'
      },
      {
          text: 'Cadastrar',
          link: '/calendar/edit'
      }
  ]
};

const Program = {
  text: 'Programa',
  link: '/program',
  icon: 'icon-note',
  submenu: [
      {
          text: 'Buscar',
          link: '/program/list'
      },
      {
          text: 'Cadastrar',
          link: '/program/edit'
      }
  ]
};

/* Lançamentos SIG - Inicio */
const headingRegisterSig = {
  text: 'Lançamentos',
  heading: true
};

const FrequencyRegister = {
  text: 'Frequência',
  link: '/frequencySig',
  icon: 'icon-note',
  submenu: [
    {
      text: 'Buscar',
      link: '/frequencySig/frequencySigList'
    },
    {
      text: 'Lançamento',
      link: '/frequencySig/frequencySigRegister'
    }
  ]
};

const FrequencyStudentsRecordsSig = {
  text: 'Frequência - Nível Aluno',
  link: '/frequencyStudentSig',
  icon: 'icon-note',
  submenu: [
    {
      text: 'Buscar',
      link: '/frequencyStudentSig/studentRecordsSigList'
    },
    {
      text: 'Lançamento',
      link: '/frequencyStudentSig/studentRecordsSigRegister'
    }
  ]
};

const PaceRecordsSigRegister = {
  text: 'Ritmo',
  link: '/paceRecordsSig',
  icon: 'icon-note',
  submenu: [
    {
      text: 'Buscar',
      link: '/paceRecordsSig/paceRecordsSigList'
    },
    {
      text: 'Lançamento',
      link: '/paceRecordsSig/paceRecordsSigRegister'
    }
  ]
};

const TestRecordsSigRegister = {
  text: 'Teste',
  link: '/testRecordsSig',
  icon: 'icon-note',
  submenu: [
    {
      text: 'Buscar',
      link: '/testRecordsSig/testRecordsSigList'
    },
    {
      text: 'Lançamento',
      link: '/testRecordsSig/testRecordsSigRegister'
    }
  ]
};

const TestRecordsStudentsSigRegister = {
  text: 'Teste - Nível Aluno',
  link: '/testStudentRecordsSig',
  icon: 'icon-note',
  submenu: [
    {
      text: 'Buscar',
      link: '/testStudentRecordsSig/testRecordsStudentSigList'
    },
    {
      text: 'Lançamento',
      link: '/testStudentRecordsSig/testRecordsStudentSigRegister'
    }
  ]
};



/* Lançamentos SIG - Fim*/

/* Configurações SIG - Inicio*/
const headingConfigurationSig = {
  text: 'Configurações',
  heading: true
};

const School_size = {
  text: 'Porte das Escolas',
  link: '/school_size',
  icon: 'icon-note',
  submenu: [
      {
          text: 'Buscar',
          link: '/school_size/list'
      },
      {
          text: 'Cadastrar',
          link: '/school_size/edit'
      }
  ]
};

const School_type = {
  text: 'Tipos de Escolas',
  link: '/school_type',
  icon: 'icon-note',
  submenu: [
      {
          text: 'Buscar',
          link: '/school_type/list'
      },
      {
          text: 'Cadastrar',
          link: '/school_type/edit'
      }
  ]
};

const School_days = {
  text: 'Dias Letivos',
  link: '/school_days',
  icon: 'icon-note',
  submenu: [
    {
      text: 'Listar',
      link: '/school_days/list'
    },
    {
      text: 'Cadastrar',
      link: '/school_days/edit'
    }
  ]
}

const Position = {
    text: 'Cargos',
    link: '/position',
    icon: 'icon-note',
    submenu: [
        {
            text: 'Buscar',
            link: '/position/list'
        },
        {
            text: 'Cadastrar',
            link: '/position/edit'
        }
    ]

  
};

const Profile = {
  text: 'Perfil',
  link: '/profile',
  icon: 'icon-note',
  submenu: [
    {
      text: 'Buscar',
      link: '/profile/list'
    },
    {
      text: 'Cadastrar',
      link: '/profile/edit'
    }
  ]
}

const CustomerSig = {
  text: 'Clientes',
  link: '/customerSig',
  icon: 'icon-note',
  submenu: [
    {
     text: 'Buscar',
     link: '/customerSig/list'
    },
    {
      text: 'Cadastrar',
      link: '/customerSig/edit'
    }
  ]
};

const UserSig = {
  text: 'Usuários',
  link: '/userSig',
  icon: 'icon-note',
  submenu: [
    {
      text: 'Buscar',
      link: '/userSig/list'
    },
    {
      text: 'Cadastrar',
      link: '/userSig/edit'
    }
  ]
};

const SchoolSigCad = {
  text: 'Escolas',
  link: '/schoolSig',
  icon: 'icon-note',
  submenu: [
    {
      text: 'Buscar',
      link: '/schoolSig/list'
    },    
    {
      text: 'Cadastrar',
      link: '/schoolSig/edit'
    }
  ]
}

const SchoolSigList = {
  text: 'Visualizar Escolas',
  link: '/schoolSig',
  icon: 'icon-note',
  submenu: [
    {
      text: 'Buscar',
      link: '/schoolSig/list'
    }
  ]
}

const Customer_type = {
  text: 'Tipos de Municípios',
  link: '/customer_type',
  icon: 'icon-note',
  submenu: [
      {
          text: 'Buscar',
          link: '/customer_type/list'
      },
      {
          text: 'Cadastrar',
          link: '/customer_type/edit'
      }
  ]


};

const Book_follow_type = {
  text: 'Tipos de Acompanhamento',
  link: '/book_follow_type',
  icon: 'icon-note',
  submenu: [
      {
          text: 'Buscar',
          link: '/book_follow_type/list'
      },
      {
          text: 'Cadastrar',
          link: '/book_follow_type/edit'
      }
  ]


};

const Program_type = {
  text: 'Tipos de Programa',
  link: '/program_type',
  icon: 'icon-note',
  submenu: [
      {
          text: 'Buscar',
          link: '/program_type/list'
      },
      {
          text: 'Cadastrar',
          link: '/program_type/edit'
      }
  ]


};

const Shift = {
  text: 'Turnos',
  link: '/shift',
  icon: 'icon-note',
  submenu: [
      {
          text: 'Buscar',
          link: '/shift/list'
      },
      {
          text: 'Cadastrar',
          link: '/shift/edit'
      }
  ]


};

const Calendar_process = {
  text: 'Processo dos Calendários',
  link: '/calendar_process',
  icon: 'icon-note',
  submenu: [
      {
          text: 'Buscar',
          link: '/calendar_process/list'
      },
      {
          text: 'Cadastrar',
          link: '/calendar_process/edit'
      }
  ]


};

const Calendar_status = {
  text: 'Status dos Calendários',
  link: '/calendar_status',
  icon: 'icon-note',
  submenu: [
      {
          text: 'Buscar',
          link: '/calendar_status/list'
      },
      {
          text: 'Cadastrar',
          link: '/calendar_status/edit'
      }
  ]


};

const Month_reference = {
  text: 'Ano-Mês Referência',
  link: '/month_reference',
  icon: 'icon-note',
  submenu: [
      {
          text: 'Buscar',
          link: '/month_reference/list'
      },
      {
          text: 'Cadastrar',
          link: '/month_reference/edit'
      }
  ]


};



/* Configurações SIG - Fim*/

/* Parâmetros SIG  - Inicio*/

const headingParameterSig = {
  text: 'Parâmetros',
  heading: true
};

const ParametersSig = {
  text: 'Parâmetros Sig',
  link: '/parametersSig',
  icon: 'icon-note',
  submenu:[
    {
      text: 'Cliente Alfabetização',
      link: '/parametersSig/customerdisciplineEdit'
    },
    {
      text: 'Programa Disciplina',
      link: '/parametersSig/programDisciplineEdit'
    },
    {
      text: 'Programa Teste',
      link: '/parametersSig/programTestEdit'
    },
  ]
}

/* Parâmetros SIG  - Fim*/

/* Relatórios QDS SIG - Inicio*/
const headingReportSig = {
  text: 'Relatórios',
  heading: true
};

const ReportQDSSig = {
  text: 'Quadro Geral',
  link: '/reportSig',
  icon: 'icon-note',
  submenu: [
    {
      text: 'Frequência',
      link: '/reportSig/qdsFrequency'
    },
    {
      text: 'Ritmo',
      link: '/reportSig/qdsPace'
    },
    {
      text: 'Teste',
      link: '/reportSig/qdsTest'
    }

  ],
};

const ReportQDSSigSchool ={
  text: 'Quadro por Escola',
  link: '/reportSig',
  icon: 'icon-note',
  submenu: [
    {
      text: 'Quadro por Escola',
      link: '/reportSig/qdsSchool'
    }
   ]

}

const ReportSig = {
  text: 'Status de Lançamento',
  link: '/reportSig',
  icon: 'icon-note',
  submenu: [
    {
      text: 'Frequência',
      link: '/reportSig/qdsFrequencyClass'
    },
    {
      text: 'Ritmo',
      link: '/reportSig/qdsPaceClass'
    },
    {
      text: 'Testes',
      link: '/reportSig/qdsTestClass'
    }
  ],
};

const ExportCsv = {
  text: 'Exportar CSV',
  link: '/exportCsv',
  icon: 'icon-note',
  submenu: [
    {
      text: 'Frequência',
      link: '/exportCsv/exportFrequency'
    },
    {
      text: 'Ritmo',
      link: '/exportCsv/exportPace'
    },
    {
      text: 'Teste',
      link: '/exportCsv/exportTest'
    },

    
  ],
};

const ReportLaunchLighthouse = {
  text: 'Farol de Lançamentos',
  link: '/reportSig',
  icon: 'icon-note',
  submenu: [
    {
      text: 'Frequência',
      link: '/reportSig/launchFrequency'
    },
    {
      text: 'Ritmo',
      link: '/reportSig/launchPace'
    },
    {
      text: 'Teste',
      link: '/reportSig/launchTest'
    }

  ]
}




/* Relatórios QDS SIG - Fim*/



/* Menu ajuda * - Inicio */
const headingHelpSig = {
  text: 'Ajuda',
  heading: true
};

export const Help = {
  text: 'Ajuda',
  icon: 'icon-question', 
  submenu: [
    {
      text: 'Manual SIG',
      elink: 'https://bit.ly/IAB-ManualSIG',
      icon: 'icon-info',
      target: '_blank',
      external: true
    },
    {
      text: 'Tutoriais em Vídeo',
      elink: 'https://www.youtube.com/watch?v=yg9N14BcLtQ',
      icon: 'icon-social-youtube', 
      target: '_blank', 
      external: true
    }
  ]
};



/* Menu ajuda - Fim */

export const menu = [
   /*Ajuda*/
   headingHelpSig,
   Help,

   /*Cadastros*/
    headingGeneralSig,
    CustomerSig,
    SchoolSigCad,
    SchoolSigList,
    Program,
    Discipline,
    Test,
    Book,
    Calendar,
    UserSig,
    StudentsSig,
    Coordinator,
    Profile,
    /*Lançamentos*/
    headingRegisterSig,
    FrequencyRegister,
    PaceRecordsSigRegister,
    TestRecordsSigRegister,
    //FrequencyStudentsRecordsSig,
    //TestRecordsStudentsSigRegister,

    /*Relatórios*/
    headingReportSig,
    ReportQDSSig,
    ReportQDSSigSchool,
    ExportCsv,
    ReportSig,
    ReportLaunchLighthouse,

    /*Configurações*/
    headingConfigurationSig,
    Customer_type,
    School_type,
    Program_type,
    Book_follow_type,
    School_size,
    Month_reference,
    Shift,
    Calendar_process,
    Calendar_status,
    Position,

    /*Parâmetros*/
    headingParameterSig,
    ParametersSig,
    School_days,
];


